import React from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native-web';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import paymentIcon from "../icons/DupeIcons-22-Payment.png";
import calendarIcon from "../icons/DupeIcons-23-Calendar.png";
import pencilIcon from "../icons/blue-pencil.png";
import blueLandscapeIcon from '../icons/blue-landscape-icon.png';

const CampaignPreview = ({ campaign, alternateBackground, campaignOpened }) => {
    const navigate = useNavigate();

    const formatDate = () => {
        if (!campaign.startDate || !campaign.endDate) {
            return 'Dates'
        }
        const monthFormatter = new Intl.DateTimeFormat('default', {month: 'long', day: 'numeric'});
        const startDate = Date.parse(campaign.startDate)
        const endDate = Date.parse(campaign.endDate)
        return monthFormatter.format(startDate) + ' - ' + monthFormatter.format(endDate)
    }

    const formatCompensation = () => {
        if (campaign.id === 'template') {
            return "Compensation"
        } else if (campaign.paymentCompensation && campaign.productCompensation) {
            return "Payment and Free Product"
        } else if (!campaign.paymentCompensation && campaign.productCompensation) {
            return "$0 and Free Product"
        } else if (campaign.paymentCompensation && !campaign.productCompensation) {
            return "Payment"
        } else {
            return "$0"
        }
    }

    return (
        <View style={alternateBackground ? styles.alternateCampaignPreviewContainer : styles.campaignPreviewContainer}>
            <Image 
                style={styles.campaignPreviewImage} 
                source={campaign.imageExists 
                    ? {uri: 'https://d3p3fw3rutb1if.cloudfront.net/business/photos/' + campaign.imageId}
                    : blueLandscapeIcon
                }
                defaultSource={blueLandscapeIcon}
                resizeMode="cover"
            />
            <View style={styles.contentContainer}>
                <View style={styles.leftColumn}>
                    <View style={styles.campaignInfoContainer}>
                        <View>
                            <Text style={styles.campaignNameText}>{campaign.name || 'Campaign Name'}</Text>
                        </View>
                        <View>
                            <View style={styles.infoRow}>
                                <Image style={styles.campaignPreviewIcon} source={paymentIcon}/>
                                <Text style={styles.subtleText}>{formatCompensation()}</Text>
                            </View>
                            <View style={styles.infoRow}>
                                <Image style={styles.campaignPreviewIcon} source={calendarIcon}/>
                                <Text style={styles.subtleText}>{formatDate()}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                {campaign.id !== 'template' && <View style={styles.rightColumn}>
                    <View style={styles.statusContainer}>
                        <Text style={styles.subtleText}>{campaign.private ? 'Private' : 'Public'}</Text>
                        <Image style={[styles.campaignPreviewIcon, styles.pencilIcon]} source={pencilIcon} onClick={() => {navigate('/campaigns/' + campaign.slug + '/edit')}}/>
                    </View>
                    <Button
                        onPress={() => navigate('/creators/search')}
                        text="Find Creators"
                        variant='tertiary'
                    />
                    {!campaignOpened && (
                        <Button
                            onPress={() => navigate('/campaigns/' + (campaign.slug || ''))}
                            text="Open Campaign"
                        />
                        )}
                </View>}
                {campaign.id === 'template' && <View style={styles.rightColumn}>
                    <TouchableOpacity 
                        style={[styles.btn, styles.blueBtn]}
                            onPress={() => navigate('/campaigns/create')}
                        >
                            <Text style={styles.blueBtnText}>Create Campaign</Text>
                        </TouchableOpacity>
                </View>}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    contentContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1,
    },
    leftColumn: {
        justifyContent: "center",
    },
    campaignInfoContainer: {
        justifyContent: 'space-between',
        flex: 0.85,
    },
    rightColumn: {
        justifyContent: 'space-between',
        margin: 10,
    },
    infoRow: {
        flexDirection: 'row',
        margin: 2,
    },
    statusContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    pencilIcon: {
        marginLeft: 10,
        cursor: 'pointer',
    },
    campaignPreviewContainer: {
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: '#b8cbf5',
        marginBottom: 15,
        borderRadius: 10,
        padding: 5,
        marginLeft: '2.5%',
        marginRight: '2.5%',
        display: 'flex',
        flexDirection: 'row',
      },
    alternateCampaignPreviewContainer: {
        backgroundColor: '#f2f1f9',
        borderWidth: 1,
        borderColor: '#b8cbf5',
        marginBottom: 15,
        borderRadius: 10,
        padding: 5,
        marginLeft: '2.5%',
        marginRight: '2.5%',
        display: 'flex',
        flexDirection: 'row',
    },
    campaignPreviewImage: {
        height: 100,
        width: 100,
        borderRadius: 5,
        margin: 10,
    },
    campaignNameText: {
        color: '#737373',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 900,
        fontSize: 24,
        margin: 2
    },
    subtleText: {
        color: '#737373',
        fontFamily: "'lato', sans-serif",
        fontSize: 12
    },
    campaignPreviewIcon: {
        height: 15,
        width: 15,
        marginRight: 5,
    },
    btn: {
        borderRadius: 25,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        width: 150,
    },
    lightBlueBtn: {
        backgroundColor: '#e6f2ff',
    },
    blueBtn: {
        backgroundColor: '#396de0',
    },
    lightBlueBtnText: {
        color: '#396de0',
        fontFamily: "'lato', sans-serif",
        fontWeight: 'bold',
    },
    blueBtnText: {
        color: 'white',
        fontFamily: "'lato', sans-serif",
        fontWeight: 'bold',
    },
});

export default CampaignPreview;