import axios from "axios";
import {fetchAuthSession, signOut} from "aws-amplify/auth";

const dupeBusinessClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT
});

dupeBusinessClient.interceptors.request.use(function (config) {
    return new Promise((resolve, reject) => {
        //get the current logged in user
        fetchAuthSession()
            .then((session) => {
                config.headers.set('X-CLIENT-ID', session.tokens.idToken.toString());
                resolve(config);
            })
            .catch((error) => {
                // No logged-in user: don't set auth header
                signOut();
            });
    });
});

// Helpful Debugging tools
// dupeClient.interceptors.request.use(request => {
//     console.log('Starting Request', JSON.stringify(request, null, 2))
//     return request
// })
//
// dupeClient.interceptors.response.use(response => {
//     console.log('Response:', JSON.stringify(response, null, 2))
//     return response
// })


export default dupeBusinessClient;