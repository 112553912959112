import React from 'react';
import { View, Text, StyleSheet } from 'react-native-web';

const Header = ({ title }) => {
  return (
    <View style={[styles.container]}>
      <Text style={[styles.text]}>{title}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FCF5E8',
    padding: 10,
  },
  text: {
    color: '#396de0',
    fontFamily: "'forma-djr-display', sans-serif",
    fontSize: 24,
    fontWeight: 700,
  },
});

export default Header;