import React, {useEffect, useState} from 'react'
import {
    createPaymentMethod,
    getBusinessUsers, getPaymentMethods,
    getSocialAccounts,
    getStripeIntent,
    getSubscription,
    inviteUser
} from "../services/BusinessService";
import plusIcon from "../icons/grey-plus-btn.png";
import Modal from "react-modal";
import {loadStripe} from "@stripe/stripe-js";
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import pencilIcon from "../icons/blue-pencil.png";
import AccountCard from "./AccountCard";    
import Header from './Header';
import Button from './Button';
import instagramIcon from "../icons/DupeIcons-Instagram.png"
import { View, StyleSheet, Text, Image, TextInput, Pressable } from 'react-native-web';
const stripePromise = loadStripe('pk_test_51PWON1AzMP8rKeYu5wkeKGqhypzJY243D2DAglj5VakbuJozKaNdMvJ1xfcDVOzNrjMX8iDaMITZJDpqt4CdQPGn00Eu3rjcuc');

const Account = () => {
    const [facebookUrl] = useState("https://www.facebook.com/v17.0/dialog/oauth?response_type=token&display=popup&client_id=1330402327595027&redirect_uri=https%3A%2F%2Fbusiness.dupephotos.com%2Faccount%2Fcallback&auth_type=rerequest&scope=instagram_basic%2Cinstagram_content_publish");
    const [instagramAccounts, setInstagramAccounts] = useState({})
    const [subscription, setSubscription] = useState({})
    const [businessUsers, setBusinessUsers] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    const [options, setOptions] = useState({})
    const [addUserModal, setAddUserModal] = useState(false)
    const [updateCCModal, setUpdateCCModal] = useState(false)
    const [inviteError, setInviteError] = useState(false)
    const [newUser, setNewUser] = useState("")

    function openCCModal() {
        getStripeIntent()
            .then(resp => {
                if (resp.status === 200 && resp.data && resp.data.client_secret) {
                    console.log(resp.data.client_secret);
                    setOptions({
                        clientSecret: resp.data.client_secret,
                        id: resp.data.id
                    });
                    setUpdateCCModal(true);
                }
            })
    }

    function closeModal() {
        setAddUserModal(false);
        setUpdateCCModal(false);
        setInviteError(false);
    }

    const updateNewUser = (event) => {
        setNewUser(event.target.value);
    }

    function triggerUserInvite(event) {
        event.preventDefault();
        if (newUser !== "") {
            let req = {};
            req.email = newUser;
            inviteUser(req)
                .then(resp => {
                    closeModal();
                })
                .catch(err => {
                    setInviteError(true);
                })
        }
    }

    function updateCard() {
        createPaymentMethod(options)
            .then(resp => {
                console.log(resp.data);
                if (resp.status === 200 && resp.data) {
                    closeModal();
                }
            })
    }

    useEffect(()=>{
        getSocialAccounts()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setInstagramAccounts(resp.data.instagramAccounts);
                }
            })
        getBusinessUsers()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setBusinessUsers(resp.data);
                }
            })
        getSubscription()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setSubscription(resp.data);
                }
            })
        getPaymentMethods()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    console.log(resp.data);
                    setPaymentMethods(resp.data);
                }
            })

    }, []);
    return(
        <View>
            <Header title="Account" />
            <View style={styles.socialAccountsDiv}>
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 15}}>
                    <View>
                        <Text style={[styles.sectionText, {marginBottom: 10}]}>Subscription</Text>
                        <View style={styles.sectionBackground}>
                            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Text style={styles.blueText}>{subscription.name}</Text>
                                <Text style={styles.priceText}> ${subscription.price} / month</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.cardsDiv}>
                        <Text style={[styles.sectionText, {marginBottom: 10}]}>Card on File</Text>
                        <View>
                            {paymentMethods && paymentMethods.length > 0 && (
                                <View style={styles.sectionBackground}>
                                    <Text style={styles.paymentText}>&#8226;&#8226;&#8226;&#8226;</Text>
                                    <Text style={styles.paymentText}>{paymentMethods[0].card.last4}</Text>
                                    <Text style={styles.paymentText}>{paymentMethods[0].card.exp_month}/{paymentMethods[0].card.exp_year}</Text>
                                    <Image style={styles.pencilIcon} source={pencilIcon} onClick={() => {openCCModal()}}/>
                                </View>
                            )}
                        </View>
                    </View>
                    <View style={styles.cardsDiv}>
                        <Text style={[styles.sectionText, {marginBottom: 10}]}>Linked Instagram Accounts</Text>
                        {instagramAccounts && instagramAccounts.length > 0 && instagramAccounts.map(account  => (
                            <View style={[styles.sectionBackground, {paddingTop: 10, paddingBottom: 10, alignItems: 'center'}]}>
                                <Pressable style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} onPress={()=> window.open(facebookUrl, "_blank")}>
                                    <Image style={styles.facebookProfileImg} source={{uri: account.profileImageUrl}} alt="Instagram"/>
                                    <Text style={styles.priceText}> {account.name}</Text>
                                </Pressable>
                            </View>
                        ))}
                        {(!instagramAccounts || instagramAccounts.length < 1) && (
                            <View style={[styles.sectionBackground, {paddingTop: 10, paddingBottom: 10, alignItems: 'center'}]}>
                                <Pressable style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} onPress={()=> window.open(facebookUrl, "_blank")}>
                                    <Image style={{width: 40, height: 40}} source={instagramIcon} alt="Instagram"/>
                                    <Text style={styles.priceText}> Connect Your Instagram</Text>
                                </Pressable>
                            </View>
                        )}
                    </View>
                </View>
                <View>
                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10}}>
                        <Text style={styles.sectionText}>Account Users</Text>
                        <Image style={styles.addCampaignImg} source={plusIcon} onClick={() => {setAddUserModal(true)}} />
                    </View>
                    {businessUsers && businessUsers.length > 0 && businessUsers.map(account  => (
                        <AccountCard key={account.id} account={account} />
                    ))}
                </View>
                <Modal
                    isOpen={addUserModal}
                    onRequestClose={closeModal}
                    style={styles.modal}
                >
                    <View style={{alignItems: 'center', backgroundColor: 'white', borderRadius: 10, padding: 20}}>
                        <Text style={[styles.sectionText, {margin: 10}]}>Add User</Text>
                        {inviteError && (
                            <Text style={styles.errorText}>There was an error inviting that user. They may already have an invite</Text>
                        )}
                        <View style={{width: '70%'}}>
                            <TextInput style={styles.greyInput} value={newUser} onChange={updateNewUser} placeholder="User's Email" placeholderTextColor="#737373"/>
                            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                <Button text="Cancel" onPress={closeModal} variant='secondary' style={{marginRight: 10, marginTop: 10}} />
                                <Button text="Invite" onPress={triggerUserInvite} style={{marginTop: 10}}/>
                            </View>
                        </View>
                    </View>
                </Modal>
                <Modal
                    isOpen={updateCCModal}
                    onRequestClose={closeModal}
                    style={styles.modal}
                >
                    <View style={{alignItems: 'center', backgroundColor: 'white', borderRadius: 10, padding: 20}}>
                        <Text style={[styles.sectionText, {margin: 10}]}>Update Credit Card on File</Text>
                        {options.clientSecret && (
                            <Elements stripe={stripePromise} options={options}>
                                <StripeForm updateCard={updateCard} closeModal={closeModal} />
                            </Elements>
                        )}
                    </View>
                </Modal>
            </View>
        </View>

    );
}

const styles = StyleSheet.create({
    socialAccountsDiv: {
        margin: '4%',
    },
    sectionBackground: {
        backgroundColor: 'white',
        borderRadius: 8,
        padding: 20,
        borderWidth: 1,
        borderColor: '#b8cbf5',
        flexDirection: 'row',
      },
      sectionText: {
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 30,
        fontWeight: 'bold',
        color: '#396de0',
      },
      blueText: {
        color: '#396de0',
        fontFamily: "'lato', sans-serif",
        fontSize: 18,
        fontWeight: 'bold',
      },
      priceText: {
        fontSize: 18,
        fontFamily: "'lato', sans-serif",
        color: '#000000',
      },
      paymentText: {
        fontWeight: 'bold',
        fontSize: 18,
        marginLeft: 20,
      },
      cardsDiv: {
        marginLeft: 15,
      },
      addCampaignImg: {
        width: 25,
        height: 25,
        cursor: 'pointer',
    },
    pencilIcon: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        marginLeft: 20,
    },
    greyInput: {
        backgroundColor: '#EFEFEF',
        border: 'none',
        borderRadius: 5,
        width: '100%',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
    },
    modal:
    {
        overlay: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: 'rgba(57, 109, 224, .5)'
        },
        content: {
            margin: '20%',
            inset: 0,
            background: 'none',
            flex: 1,
            border: 'none',
            alignSelf: 'center',
            alignContent: 'center'
        }
    },
    errorText: {
        color: 'red',
        marginBottom: 10,
    },
    facebookProfileImg: {
        width: 40,
        height: 40,
        borderRadius: 40,
    }
});

const StripeForm = ({updateCard, closeModal}) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: "https://business.dupephotos.com/account",
            },
            redirect: "if_required",
        });

        if (error) {
            console.error(error);
            // handleError();
        } else {
            updateCard().then(() => {
                closeModal();
            });
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                <Button text="Cancel" onPress={closeModal} variant='secondary' style={{marginTop: 10, marginRight: 10}}/>
                <Button text="Submit" onPress={handleSubmit} style={{marginTop: 10}}/>
            </View>
        </form>
    )
}

export default Account
