import React, { useEffect, useState } from 'react';
import openEye from "../icons/DupeIcons_Eye-Open.png";
import closedEye from "../icons/eye-closed.png";
import { signOut, signIn, fetchAuthSession } from 'aws-amplify/auth';
import { View, TextInput, TouchableOpacity, Text, StyleSheet, Image } from 'react-native-web';
import { getProfile } from "../services/UserService";
import { useNavigate, useSearchParams } from 'react-router-dom';

const EmailLogin = ({ inviteId }) => {
    const [email, setEmail] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const navigate = useNavigate();

    const handleLogin = () => {
        if (email && password) {
            signOut().then(() => {
                signIn({
                    password: password,
                    username: email,
                    options: {
                        authFlowType: 'USER_PASSWORD_AUTH',
                    },
                })
                    .then(() => {
                        fetchAuthSession().then(session => {
                            if (inviteId != null) {
                                navigate('/join/' + inviteId);
                            } else {
                                navigate('/');
                            }
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        setErrorText(err.message)
                    });
            })
        }
    };

    useEffect(() => {
        console.log(inviteId);

    }, []);

    return (
        <View style={{ alignItems: 'center' }}>
            <View style={styles.inputView}>
                <TextInput
                    style={styles.inputText}
                    placeholder="Email"
                    placeholderTextColor="white"
                    selectionColor="white"
                    autoCapitalize="none"
                    value={email}
                    onChangeText={text => setEmail(text)}
                    onSubmitEditing={handleLogin}
                />
            </View>
            <View style={styles.inputView}>
                <TextInput
                    style={styles.inputText}
                    placeholder="Password"
                    placeholderTextColor="white"
                    selectionColor="#236fea"
                    autoCapitalize="none"
                    secureTextEntry={!showPassword}
                    value={password}
                    onChangeText={text => setPassword(text)}
                    onSubmitEditing={handleLogin}
                />
                <TouchableOpacity onPress={() => setShowPassword(!showPassword)}>
                    <Image source={showPassword ? openEye : closedEye} style={{ width: 20, height: 20, marginLeft: 10 }} />
                </TouchableOpacity>
            </View>
            <Text style={styles.text} onPress={() => window.open('https://dupephotos.com/forgot_password', '_blank')}>
                Forgot Password?
            </Text>
            <TouchableOpacity
                onPress={handleLogin}
                style={styles.loginBtn}>
                <Text style={[styles.loginText]}>Login</Text>
            </TouchableOpacity>
            {errorText && <Text style={styles.errorText}>{errorText}</Text>}
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={styles.text}>Don't have an account?</Text>
                <Text style={[styles.text, { color: 'blue' }]} onPress={() => window.open('https://dupephotos.com/signup', '_blank')}> Sign up</Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    inputView: {
        width: 350,
        backgroundColor: "#236fea", 
        borderRadius: 25,
        height: 50,
        marginBottom: 20,
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 20,
        flexDirection: "row",
    },
    inputText: {
        flex: 1,
        color: "white",  
        fontFamily: "'Lato', sans-serif",
        border: "none",
        paddingVertical: 10, 
        paddingHorizontal: 10,
        borderRadius: 25,
        backgroundColor: "#236fea", 
        outlineWidth: 0,
        fontSize: 16,
        // The code below is supposed to force the web browser's autofill to match the text box, but it doesn't. Hours waisted here: 4. Increase counter if you attempt. 
        '::WebkitAutofill': {
            WebkitBoxShadow: '0 0 0px 1000px #236fea inset',  
            WebkitTextFillColor: 'white',  
            backgroundColor: "#236fea",  
        },
        '::WebkitAutofillActive': {
            WebkitBoxShadow: '0 0 0px 1000px #236fea inset',  
            WebkitTextFillColor: 'white',  
            backgroundColor: "#236fea", 
        },
        '::WebkitAutofillHovered': {
            WebkitBoxShadow: '0 0 0px 1000px #236fea inset',
            WebkitTextFillColor: 'white',
            backgroundColor: "#236fea",
        },
        '::WebkitAutofillFocused': {
            WebkitBoxShadow: '0 0 0px 1000px #236fea inset',
            WebkitTextFillColor: 'white',
            backgroundColor: "#236fea",
        },
    },
    loginBtn: {
        width: 350,
        backgroundColor: "#c6ca53",
        borderRadius: 25,
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 40,
        marginBottom: 10
    },
    loginText: {
        color: "white",
        fontFamily: "'Lato', sans-serif",
        fontSize: 20
    },
    errorText: {
        color: 'red',
        fontFamily: "'Lato', sans-serif",
        fontSize: 16
    },
    text: {
        color: "black",
        fontFamily: "'Lato', sans-serif",
        fontSize: 16
    },
});


export default EmailLogin;
