import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native-web';

const AccountCard = ({ account }) => {
  return (
    <View style={styles.container}>
      <Image
        style={styles.profileImage}
        source={{ uri: `https://d3p3fw3rutb1if.cloudfront.net/users-preview/${account.id}` }}
      />
      <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flex: 1, marginLeft: 20, marginRight: 20}}>
        <View style={styles.textContainer}>
            <Text style={styles.nameText}>{account.first_name} {account.last_name}</Text>
            <Text style={styles.emailText}>{account.email}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 16,
    marginBottom: 16,
    alignItems: 'center',
    border: '1px solid #b8cbf5',
    flexDirection: 'row',
  },
  textContainer: {
    
  },
  profileImage: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  nameText: {
    fontSize: 18,
    fontWeight: 900,
    color: '#396de0',
    fontFamily: "'lato', sans-serif",
    marginBottom: 4,
  },
  emailText: {
    fontSize: 16,
    color: '#737373',
    fontFamily: "'lato', sans-serif",
  },
});

export default AccountCard;