import React, {useEffect, useState} from 'react'
import {useNavigate, useSearchParams, useLocation} from "react-router-dom";
import {saveAccessToken} from "../services/UserService";


const AccessToken = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { hash } = useLocation();

    useEffect(()=>{
        let accessToken = null;
        if (hash) {
            const vars = hash.split("&");
            if (vars.length >= 1) {
                const accessTokenParam = vars[0];
                if (accessTokenParam) {
                    const split = accessTokenParam.split("=");
                    if (split.length > 1) {
                        accessToken = split[1]
                    }
                }
            }
        }
        if (accessToken) {
            let req = {};
            req["accessToken"] = accessToken;
            saveAccessToken(req)
                .then(resp => {
                    navigate('/');
                })
        }

    }, []);
    return(
        <div>
        </div>

    );
}

export default AccessToken
