import React, { useState, useCallback } from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native-web';

const Button = ({ onPress, text, variant = 'primary', style }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = useCallback(() => setIsHovered(true), []);
    const handleMouseLeave = useCallback(() => setIsHovered(false), []);

    const getStyles = () => {
        switch (variant) {
            case 'secondary':
                return {
                    button: styles.greyBtn,
                    buttonHovered: styles.greyBtnHovered,
                    text: styles.greyBtnText
                };
            case 'tertiary':
                return {
                    button: styles.lightBlueBtn,
                    buttonHovered: styles.lightBlueBtnHovered,
                    text: styles.lightBlueBtnText
                };
            default:
                return {
                    button: styles.blueBtn,
                    buttonHovered: styles.blueBtnHovered,
                    text: styles.blueBtnText
                };
        }
    };

    const { button, buttonHovered, text: textStyle } = getStyles();

    return (
        <TouchableOpacity 
            style={[button, isHovered && buttonHovered, style]}
            onPress={onPress}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Text style={textStyle}>{text}</Text>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    blueBtn: {
        backgroundColor: '#396de0',
        borderRadius: 25,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        width: 150,
        transition: 'background-color 0.3s ease',
    },
    blueBtnHovered: {
        backgroundColor: '#2a51a8',
    },
    blueBtnText: {
        color: 'white',
        fontFamily: "'lato', sans-serif",
        fontWeight: 'bold',
    },
    greyBtn: {
        backgroundColor: '#f0f0f0',
        borderRadius: 25,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        width: 150,
        transition: 'background-color 0.3s ease',
    },
    greyBtnHovered: {
        backgroundColor: '#e0e0e0',
    },
    greyBtnText: {
        color: '#737373',
        fontFamily: "'lato', sans-serif",
        fontWeight: 'bold',
    },
    lightBlueBtn: {
        backgroundColor: '#e6f2ff',
        borderRadius: 25,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        width: 150,
        transition: 'background-color 0.3s ease',
    },
    lightBlueBtnHovered: {
        backgroundColor: '#d1e6ff',
    },
    lightBlueBtnText: {
        color: '#396de0',
        fontFamily: "'lato', sans-serif",
        fontWeight: 'bold',
    },
});

export default Button;