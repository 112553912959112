import React, { useEffect, useState } from 'react'
import {
    createBusinessImage,
    getBrandHub,
    getBusinessNotifications,
    updateBusinessInfo
} from "../services/BusinessService";
import { useNavigate } from "react-router-dom";
import pencilIcon from "../icons/blue-pencil.png";
import instagramIcon from "../icons/DupeIcons-Instagram.png";
import tiktokIcon from "../icons/DupeIcons-TikTOk.png";
import "../stylesheets/BrandHub.css";
import Modal from "react-modal";
import { getCampaignSlug } from "../services/CampaignService";
import CampaignPreview from './CampaignPreview';
import Header from './Header';
import Button from './Button';
import { View, StyleSheet, Pressable, Text } from 'react-native-web';
import ReactPlayer from 'react-player/lazy';

const BrandHub = () => {
    const [businessData, setBusinessData] = useState({})
    const [newBizInfo, setNewBizInfo] = useState({})
    const [notifications, setNotifications] = useState({})
    const [editBizModal, setEditBizModal] = useState(false)
    const navigate = useNavigate();

    const defaultStyle = {
        overlay: {
            backgroundColor: 'rgba(24, 47, 96, 0.80)',
            position: 'fixed',
            zIndex: 999
        }
    };

    function afterOpenModal() {

    }
    function closeModal() {
        setEditBizModal(false);
    }
    function openModal() {
        setNewBizInfo(businessData.business)
        setEditBizModal(true);
    }

    const updateName = (event) => {
        setNewBizInfo(prevState => ({
            ...prevState,    // keep all other key-value pairs
            name: event.target.value       // update the value of specific key
        }))
    }

    const updateDescription = (event) => {
        setNewBizInfo(prevState => ({
            ...prevState,    // keep all other key-value pairs
            description: event.target.value       // update the value of specific key
        }))
    }

    const updateInstagram = (event) => {
        setNewBizInfo(prevState => ({
            ...prevState,    // keep all other key-value pairs
            instagramHandle: event.target.value       // update the value of specific key
        }))
    }
    const updateTikTok = (event) => {
        setNewBizInfo(prevState => ({
            ...prevState,    // keep all other key-value pairs
            tikTokHandle: event.target.value       // update the value of specific key
        }))
    }
    const updateYoutube = (event) => {
        setNewBizInfo(prevState => ({
            ...prevState,    // keep all other key-value pairs
            youtubeHandle: event.target.value       // update the value of specific key
        }))
    }

    const setUploadImage = (event) => {
        event.preventDefault();
        setNewBizInfo(prevState => ({
            ...prevState,    // keep all other key-value pairs
            logo: event.target.files[0],     // update the value of specific key
            newLogo: true
        }))
    }

    function saveBusinessInfo(event) {
        event.preventDefault();
        let req = {};
        req.name = newBizInfo.name;
        req.description = newBizInfo.description;
        req.logoImgId = newBizInfo.logoImgId;
        req.instagramHandle = newBizInfo.instagramHandle;
        req.tikTokHandle = newBizInfo.tikTokHandle;
        req.youtubeHandle = newBizInfo.youtubeHandle;
        if (newBizInfo.newLogo) {
            let formData = new FormData();
            formData.append("img", newBizInfo.logo);
            createBusinessImage(formData)
                .then(resp => {
                    updateBusinessInfo(req)
                        .then(bizResp => {
                            if (bizResp.status === 200 && bizResp.data) {
                                setBusinessData(prevState => ({
                                    ...prevState,
                                    business: bizResp.data
                                }))
                            }
                            closeModal();
                        })
                        .catch(err => {
                            closeModal();
                        })
                });
        } else {
            updateBusinessInfo(req)
                .then(bizResp => {
                    if (bizResp.status === 200 && bizResp.data) {
                        setBusinessData(prevState => ({
                            ...prevState,
                            business: bizResp.data
                        }))
                    }
                    closeModal();
                })
                .catch(err => {
                    closeModal();
                })
        }
    }

    function handleNotificationRedirect(notification) {
        if (notification.type === "CAMPAIGN_APPLIED") {
            getCampaignSlug(notification.user_id)
                .then(resp => {
                    if (resp.status === 200 && resp.data && resp.data.slug) {
                        navigate('/campaigns/' + resp.data.slug)
                    }
                })
        } else if (notification.type === "CAMPAIGN_CONTENT_UPLOADED") {
            navigate('/content?user=' + notification.actor_id);
        }
    }

    useEffect(() => {
        getBrandHub()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setBusinessData(resp.data);
                }
            })
        getBusinessNotifications()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setNotifications(resp.data);
                }
            })

    }, [navigate]);

    return (
        <div>
            <Header title={"Brand Hub"} />
            <div className={"hub-top-container"}>
                <div className={"business-overview-div"}>
                    <div className={"white-container-div business-info-div"}>
                        <div className={"brand-logo-div"}>
                            {businessData.business && (
                                <img className={"logo-img"} alt="" src={businessData.business.logoExists ? "https://d3p3fw3rutb1if.cloudfront.net/business/photos/" + businessData.business.businessId : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXw3NjA4Mjc3NHx8ZW58MHx8fHw%3D&w=1000&q=80"} />
                            )}
                            <div className={"brand-socials-div"}>
                                {businessData.business && businessData.business.instagramHandle && (
                                    <a href={"https://www.instagram.com/" + businessData.business.instagramHandle} target={"_blank"} rel="noreferrer">
                                        <img src={instagramIcon} alt="" className={"brand-social-icon"} />
                                    </a>
                                )}
                                {businessData.business && businessData.business.instagramHandle && (
                                    <a href={"https://www.tiktok.com/@" + businessData.business.tikTokHandle} target={"_blank"} rel="noreferrer">
                                        <img src={tiktokIcon} alt="" className={"brand-social-icon"} />
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className={"brand-info-div"}>
                            {businessData.business && (
                                <h4 className={"brand-h font-bold"}>{businessData.business.name}</h4>
                            )}
                            {businessData.business && (
                                <p className={""}>{businessData.business.description}</p>
                            )}
                        </div>
                        <img className={"brand-pencil-img absolute-10-10"} alt="" src={pencilIcon} onClick={openModal} />
                    </div>
                    <div className={"white-container-div notifications-div"}>
                        <h3 className={"font-bold blue-text notification-h"}>Notifications</h3>
                        {(!notifications || notifications.length < 1) && (
                            <p>You have no notifications yet</p>
                        )}
                        <div className="notifications-scroll-div">
                            {notifications && notifications.length > 0 && notifications.map(notification => (
                                <div className="notification-div" key={notification.id}>
                                    <div className="notification-content">
                                        <span className="bullet-span" />
                                        <Pressable onPress={() => { handleNotificationRedirect(notification) }} style={styles.notificationPressable}>
                                            {notification.type === "CAMPAIGN_CONTENT_UPLOADED" && (
                                                <div className="campaign-notification-text-div">
                                                    <p className="light-grey bold-p p-14">{notification.actor_first_name} has uploaded content</p>
                                                    <p className="light-grey p-14">- {notification.user_name}</p>
                                                </div>
                                            )}
                                            {notification.type === "CAMPAIGN_APPLIED" && (
                                                <div className="campaign-notification-text-div">
                                                    <p className="light-grey bold-p p-14">{notification.actor_first_name} has applied to your campaign</p>
                                                    <p className="light-grey p-14">- {notification.user_name}</p>
                                                </div>
                                            )}
                                            <p className="elapsed-time-p light-grey margin-0">{notification.elapsed_time}</p>
                                        </Pressable>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={"white-container-div campaign-hub-div"}>
                    <h3 className={"font-bold blue-text"}>Active Campaigns</h3>
                    <div className={"campaigns-div"}>
                        {businessData.campaigns && businessData.campaigns.map(campaign => (
                            <CampaignPreview key={campaign.id} campaign={campaign} alternateBackground={true} />
                        ))}
                    </div>
                </div>
            </div>
            {businessData.content && businessData.content.length > 0 && (
                <div>
                    <h3 className={"font-bold blue-text"}>Your Content</h3>
                    <div className={"white-container-div content-preview-div"}>
                        {businessData.content && businessData.content.map(image => (
                            <img className={"content-preview-img"} alt="" src={"data:" + image.image_type + ";base64," + image.img} />
                        ))}
                    </div>
                </div>
            )}
            <div>
                <div className={"creators-spotlight-div"}>
                    <h3 className={"font-bold blue-text"}>Creators We're Loving</h3>
                    <View style={{ display: 'flex', flexDirection: 'row', overflow: 'scroll', marginRight: 30 }}>
                        {businessData.creators && businessData.creators.map(creator => (
                            <div className={"hub-creator-div"}>
                                <div className={"creator-header-div"}>
                                    <img className={"creator-hub-preview-img"} alt="" src={"https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + creator.id} />
                                    <div className={"creator-info-div"}>
                                        <p className={"username-p"}>@{creator.username}</p>
                                        <p className={"follower-p"}>{creator.follower_count} followers</p>
                                    </div>
                                </div>
                                <div>
                                    <p className={"creator-section-p light-grey"}>Aesthetics</p>
                                    <div className={"creator-aesthetics-div"}>
                                        {creator.aesthetics && creator.aesthetics.map(aesthetic => (
                                            <p>{aesthetic}</p>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <p className={"creator-section-p light-grey"}>Content</p>
                                    <div className={"creator-content-div"}>
                                        {creator.content && creator.content.map(post => (
                                            <View key={post.img_preview_id} style={styles.contentItem}>
                                                {post.content_type === "VIDEO" ? (
                                                    <View style={styles.videoWrapper}>
                                                        <ReactPlayer
                                                            style={styles.videoPlayer}
                                                            width={"100%"}
                                                            height={"10vh"}
                                                            url={"https://d3p3fw3rutb1if.cloudfront.net/videos/" + post.img_preview_id}
                                                            muted={true}
                                                            playing={true}
                                                            loop={true}
                                                        />
                                                    </View>
                                                ) : (
                                                    <img className={"creator-content-preview-img"} alt="" src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + post.img_preview_id} />
                                                )}
                                            </View>

                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </View>
                </div>
            </div>
            <Modal
                isOpen={editBizModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"business-edit-modal"}
                style={defaultStyle}
            >
                <div className={"edit-modal-div"} style={styles.modal}>
                    <div className={"edit-business-header-div"}>
                        <Text style={[styles.sectionText, { marginBottom: 10, marginTop: 10 }]}>Edit Business Info</Text>
                    </div>
                    <form>
                        <View style={styles.formStyle}>
                            <div className={"col-3"} style={{ marginRight: 20 }}>
                                <div className={"headshot-box"}>
                                    {newBizInfo.newLogo && (
                                        <img className={"logo-preview"} alt=""
                                            src={URL.createObjectURL(newBizInfo.logo)} onClick={() => { document.getElementById('file').click(); }} />
                                    )}
                                    {!newBizInfo.newLogo && newBizInfo.logoExists && (
                                        <img className={"logo-preview"} alt="" src={"https://d3p3fw3rutb1if.cloudfront.net/business/photos/" + newBizInfo.businessId} onClick={() => { document.getElementById('file').click(); }} />
                                    )}
                                    {!newBizInfo.newLogo && !newBizInfo.logoExists && (
                                        <img className={"logo-preview"} alt=""
                                            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXw3NjA4Mjc3NHx8ZW58MHx8fHw%3D&w=1000&q=80" onClick={() => { document.getElementById('file').click(); }} />
                                    )}
                                </div>
                                <input type="file" style={{ display: "none" }} id="file" name="file"
                                    onChange={(event) => {
                                        setUploadImage(event);
                                    }} />
                            </div>
                            <div className={"col-8"} style={styles.col8}>
                                <div className="row">
                                    <div className="col">
                                        <input type="text" className="form-control grey-input" value={newBizInfo.name} maxLength="20" onChange={updateName} placeholder="First name" style={styles.textarea} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="relative-div" style={styles.relativeDiv}>
                                        <textarea
                                            type="text"
                                            value={newBizInfo.description}
                                            onChange={updateDescription}
                                            maxLength="115"
                                            rows="4"
                                            className="form-control grey-textarea"
                                            id="descriptionInput"
                                            placeholder="Bio"
                                            style={styles.textarea}
                                        />
                                        <span className="profile-textarea-limit-span" style={styles.textareaLimitSpan}>
                                            {115 - (newBizInfo.description ? newBizInfo.description.length : 0)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </View>
                        <div className={"row"} style={styles.rows}>
                            <div className="col relative-div">
                                <span className="at-span font-medium" id="basic-addon1">@</span>
                                <input type="text" className="form-control grey-input at-input" value={newBizInfo.instagramHandle} onChange={updateInstagram} placeholder="Instagram" />
                            </div>
                            <div className="col relative-div">
                                <span className="at-span font-medium" id="basic-addon1">@</span>
                                <input type="text" className="form-control grey-input at-input" value={newBizInfo.tikTokHandle} onChange={updateTikTok} placeholder="TikTok" />
                            </div>
                            <div className="col relative-div">
                                <span className="at-span font-medium" id="basic-addon1">@</span>
                                <input type="text" className="form-control grey-input at-input" value={newBizInfo.youtubeHandle} onChange={updateYoutube} placeholder="Youtube" />
                            </div>
                        </div>
                        <View className={"edit-profile-footer"} style={styles.footer}>
                            <Button text="Cancel" onPress={closeModal} variant='secondary' style={styles.cancelButton} />
                            <Button text="Submit" onPress={saveBusinessInfo} style={styles.submitButton} />
                        </View>
                    </form>
                </div>
            </Modal>
        </div>

    );
}

const styles = StyleSheet.create({
    contentItem: {
        width: 'auto',
        height: 'auto',
        maxHeight: '10vh',
        maxWidth: '10vw',
        marginLeft: 2,
        borderRadius: 10,
    },
    videoWrapper: {
        width: '100%',
        height: '10vh',
        borderRadius: 10,
        overflow: 'hidden',
    },
    notificationPressable: {
        marginLeft: 10,
    },
    footer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: 30,
    },
    sectionText: {
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 30,
        fontWeight: 'bold',
        color: '#396de0',
    },
    modal: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(255,255,255)',
        borderRadius: '10px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '20px',
    },
    cancelButton: {
        marginRight: 40,
    },
    submitButton: {
        marginLeft: 40,
    },
    formStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    relativeDiv: {
        position: 'relative',
        width: '100%',
    },
    textarea: {
        width: '100%',
        paddingRight: 40,
        boxSizing: 'border-box',
    },
    textareaLimitSpan: {
        position: 'absolute',
        right: 10,
        bottom: 5,
        color: '#a9a9a9',
    },
    rows: {
        marginTop: 20,
        marginRight: 13,
        marginLeft: 7,
    },
    col8: {
        marginTop: 15,
        marginLeft: 15,
    },
});

export default BrandHub
