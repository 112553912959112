import React, {useEffect, useState} from 'react'
import {getCampaignByName, getCampaignCreators, updateCampaignCreator} from "../services/CampaignService";
import {useParams} from "react-router-dom";
import "../stylesheets/Campaign.css";
import approveIcon from "../icons/DupeIcons-17-Biz-Available.png";
import denyIcon from "../icons/DupeIcons-16-Biz-Unavailable.png";
import photoIcon from "../icons/DupeIcons-14-Photo.png";
import videoIcon from "../icons/DupeIcons-12-Video.png";
import emailIcon from "../icons/DupeIcons-Mail.png";
import Modal from "react-modal";
import {getCreatorsAesthetics, getCreatorsContent, getCreatorsContentByAesthetic} from "../services/CreatorService";
import Header from './Header';
import CampaignPreview from './CampaignPreview';
import {StyleSheet, View, Image, Text, TextInput, TouchableOpacity, ScrollView, Pressable} from 'react-native-web';
import Button from './Button';
import ReactPlayer from "react-player/lazy";
import toast, {Toaster} from "react-hot-toast";

const Campaign = () => {

    const [campaign, setCampaign] = useState({})
    const [selectedCreator, setSelectedCreator] = useState({})
    const [creatorsContent, setCreatorsContent] = useState([])
    const [creatorsAesthetics, setCreatorsAesthetics] = useState([])
    const [inviteModal, setInviteModal] = useState(false)
    const [inviteFlag, setInviteFlag] = useState(false)
    const [creatorModal, setCreatorModal] = useState(false)
    const [inviteNotes, setInviteNotes] = useState("")
    const [campaignCreators, setCampaignCreators] = useState({})
    const [selectedStage, setSelectedStage] = useState("Active")
    let { campaignSlug } = useParams();


    function afterOpenModal() {

    }

    function closeModal() {
        setInviteModal(false);
        setCreatorModal(false);
    }

    function openUpdateModal(creator, inviteBool) {
        setSelectedCreator(creator);
        setInviteFlag(inviteBool);
        setInviteNotes("");
        setInviteModal(true);
    }

    function openCreatorModal(creator, aesthetic) {
        setSelectedCreator(creator);
        if (aesthetic) {
            getCreatorsContentByAesthetic(creator.id, aesthetic)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCreatorsContent(resp.data);
                }
                setCreatorModal(true);
            })
        } else {
            getCreatorsContent(creator.id)
                .then(resp => {
                    if (resp.status === 200 && resp.data) {
                        setCreatorsContent(resp.data);
                    }
                    setCreatorModal(true);
                })
        }

        getCreatorsAesthetics(creator.id)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCreatorsAesthetics(resp.data);
                }
            })
    }

    function triggerInviteCreator() {
        let req = {};
        req.creatorId = selectedCreator.id;
        if (inviteFlag) {
            req.stage = "Invited";
        } else {
            req.stage = "Accepted";
        }
        req.notes = inviteNotes;
        updateCampaignCreator(campaign.id, req)
            .then(resp => {
                closeModal();
                getCampaignCreators(campaign.id)
                    .then(creatorResp => {
                        if (creatorResp.status === 200 && creatorResp.data) {
                            setCampaignCreators(creatorResp.data);
                        }
                    })
            })
    }

    function findAestheticContent(aesthetic) {
        getCreatorsContentByAesthetic(selectedCreator.id, aesthetic)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCreatorsContent(resp.data);
                }
            })
    }

    function copyEmail(email) {
        navigator.clipboard.writeText(email);
        toast.success('Email copied');
    }

    useEffect(()=>{
        getCampaignByName(campaignSlug)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCampaign(resp.data);
                    getCampaignCreators(resp.data.id)
                        .then(creatorResp => {
                            if (creatorResp.status === 200 && creatorResp.data) {
                                setCampaignCreators(creatorResp.data);
                            }
                        })
                }
            })

    }, []);

    function CreatorPreview(creator, variant="active") {
        return (
            <View style={styles.creatorPreviewContainer}>
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 1}}>
                    <View style={{flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}}>
                        <Image
                            source={{uri: "https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + creator.id}}
                            style={styles.creatorPreviewImage}
                            onClick={() => openCreatorModal(creator)}
                        />
                        <View style={{flexDirection: 'column'}}>
                            <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}} onClick={() => openCreatorModal(creator)}>
                                <Text style={styles.creatorName}>{creator.first_name} {creator.last_name}</Text>
                                <View style={{flexDirection: 'row'}}>
                                    {creator.video && <Image source={videoIcon} style={styles.icon} />}
                                    {creator.photo && <Image source={photoIcon} style={styles.icon} />}
                                </View>
                            </View>
                            <View style={{flexDirection: 'row'}}>
                                {creator.aesthetics && creator.aesthetics.map(aesthetic  => (
                                    <View onClick={() => openCreatorModal(creator, aesthetic)}>     
                                        <Text style={styles.aestheticText}>{aesthetic}</Text>
                                    </View>
                                    ))}
                            </View>
                        </View>
                    </View>
                    {variant === "active" && (
                        <TextInput
                            multiline
                            rows={3}
                            maxLength={200}
                            // onChangeText={text => handleBioChanged(text)}
                            value={creator.notes}
                            style={styles.notesInput}
                        />
                    )}
                    {variant === "active" && creator.preferred_email && (
                        <Pressable onPress={() => {copyEmail(creator.preferred_email)}}>
                            <Image source={emailIcon} style={styles.emailIcon} />
                        </Pressable>
                    )}
                    {variant === "active" && (
                        <select style={styles.dropdown} className={"Dropdown-root"} placeholder={"Select..."} >
                            <option value="accepted">Accepted</option>
                            <option value="contract_sent">Contract Sent</option>
                            <option value="complete">Complete</option>
                        </select>
                    )}
                    {variant === "applied" && (
                        <Text style={styles.notesInput}>{creator.creator_notes}</Text>
                    )}
                    {variant === "applied" && (
                        <View style={{flexDirection: 'row'}}>
                            <Image source={approveIcon} style={styles.largeIcon} onClick={() => openUpdateModal(creator, false)}/>
                            <Image source={denyIcon} style={styles.largeIcon} />
                        </View>
                    )}
                    {variant === "shortlisted" && (
                        <Button text={"Invite"} variant={"tertiary"} onPress={() => openUpdateModal(creator, true)} />
                    )}
                </View>
            </View>
        );
    }

    function CreatorStatusSelection(stage) {
        return (
            <TouchableOpacity style={selectedStage === stage ? styles.creatorStatusSelectionHighlighted : styles.creatorStatusSelection} onPress={event => setSelectedStage(stage)}>
                <Text style={styles.boldText}>{stage}</Text>
                <Text style={styles.text}>{campaignCreators[stage.toLowerCase()] ? campaignCreators[stage.toLowerCase()].length : 0}</Text>
            </TouchableOpacity>
        );
    }
    return(
        <div>
            <Header title="Campaign" />
            <div className={"container-label-div"}/>
            <CampaignPreview campaign={campaign} campaignOpened={true} />
            <View style={styles.creatorStatusContainer}>
                {CreatorStatusSelection("Active")}
                {CreatorStatusSelection("Applied")}
                {CreatorStatusSelection("Invited")}
                {CreatorStatusSelection("Shortlisted")}
            </View>
            <View>
                {selectedStage === "Active" && campaignCreators.active && campaignCreators.active.map(creator => (
                    CreatorPreview(creator)
                ))}
                {selectedStage === "Applied" && campaignCreators.applied && campaignCreators.applied.map(creator => (
                    CreatorPreview(creator, "applied")
                ))}
                {selectedStage === "Invited" && campaignCreators.invited && campaignCreators.invited.map(creator => (
                    CreatorPreview(creator, "invited")
                ))}
                {selectedStage === "Shortlisted" && campaignCreators.shortlisted && campaignCreators.shortlisted.map(creator => (
                    CreatorPreview(creator, "shortlisted")
                ))}
            </View>
            <Modal
                isOpen={inviteModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"invite-modal"}
                style={styles.modal}
            >
                <View style={{alignItems: 'center', backgroundColor: 'white', borderRadius: 10, padding: 20}}>
                    {inviteFlag && (
                        <Text style={[styles.sectionText, {margin: 10}]}>Invite {selectedCreator.username} to your campaign</Text>
                    )}
                    {!inviteFlag && (
                        <Text style={[styles.sectionText, {margin: 10}]}>Accept {selectedCreator.username} to your campaign</Text>
                    )}
                    <View style={{width: '70%'}}>
                        <TextInput
                            multiline
                            rows={3}
                            maxLength={200}
                            style={styles.greyInput}
                            value={inviteNotes}
                            onChange={event => setInviteNotes(event.target.value)}
                            placeholder="Message to Creator"
                            placeholderTextColor="#737373"
                        />
                        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                            <Button text="Cancel" onPress={closeModal} variant='secondary' style={{marginRight: 10, marginTop: 10}} />
                            <Button text="Invite" onPress={triggerInviteCreator} style={{marginTop: 10}}/>
                        </View>
                    </View>
                </View>
            </Modal>
            <Modal
                isOpen={creatorModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"invite-modal"}
                style={styles.modal}
            >
                <View style={{alignItems: 'center', backgroundColor: 'white', borderRadius: 10, padding: 20}}>
                    <View style={{flexDirection: 'row', alignItems: 'center', width: '100%'}}>
                        <Image style={styles.creatorPreviewImage} source={"https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + selectedCreator.id} alt={""}/>
                        <View style={{flexDirection: 'column', flex: 1}}>
                            <Text style={styles.creatorName}>{selectedCreator.first_name} {selectedCreator.last_name}</Text>
                            <ScrollView horizontal={true} style={{flexDirection: 'row'}}>
                                {creatorsAesthetics && creatorsAesthetics.map(aesthetic => (
                                    <Text style={[styles.aestheticText, {cursor: 'pointer', textWrap: 'nowrap'}]} onClick={() => {findAestheticContent(aesthetic)}}>{aesthetic}</Text>
                                ))}
                            </ScrollView>
                        </View>
                    </View>
                    <View style={styles.creatorsContentGrid}>
                        {creatorsContent && creatorsContent.slice(0, 9).map((image, index) => (
                            <View style={styles.creatorContentImageView}>
                                {image.content_type === "VIDEO" && (
                                    // <img className="content-image" alt="" src={"https://d3p3fw3rutb1if.cloudfront.net/business/uploads/" + image.img_preview_id} onClick={() => openContentModal(image.id)} />
                                    <ReactPlayer width={"100%"} height={"100%"} style={styles.creatorContentImage} url={"https://d3p3fw3rutb1if.cloudfront.net/videos/" + image.img_preview_id} muted={true} playing={true} loop={true} />
                                )}
                                {image.content_type !== "VIDEO" && (
                                    <Image
                                        key={index}
                                        source={{uri: `https://d3p3fw3rutb1if.cloudfront.net/photos/${image.img_preview_id}`}}
                                        style={styles.creatorContentImage}
                                        resizeMode="cover"
                                    />
                                )}
                            </View>
                        ))} 
                    </View>

                </View>
            </Modal>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 3000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },

                    // Default options for specific types
                    success: {
                        duration: 2000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}
            />
        </div>

    );
}

const styles = StyleSheet.create({
    creatorPreviewContainer: {
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: '#b8cbf5',
        marginBottom: 15,
        borderRadius: 10,
        //padding: 5,
        marginLeft: '2.5%',
        marginRight: '2.5%',
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      creatorStatusContainer: {
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: '#b8cbf5',
        marginBottom: 15,
        borderRadius: 10,
        //padding: 5,
        marginLeft: '2.5%',
        marginRight: '2.5%',
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      creatorPreviewImage: {
        width: 80,
        height: 80,
        margin: 10,
        borderRadius: 50,
      },
      creatorName: {
        color: '#396de0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 20,
        fontWeight: 700,
        marginBottom: 5,
      },
      aestheticText: {
        fontSize: 12,
        color: '#396de0',
        borderWidth: 1,
        borderColor: '#396de0',
        borderRadius: 50,
        fontFamily: "'lato', sans-serif",
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 10,
        paddingRight: 10,
        marginRight: 5,
      },
      icon: {
        width: 25,
        height: 25,
      },
      largeIcon: {
        width: 30,
        height: 30,
        marginRight: 5,
        cursor: 'pointer'
      },
      notesInput: {
        padding: 10,
        width: '30%',
        display: 'flex',
        color: 'grey',
        fontFamily: "'lato', sans-serif",
        borderWidth: 1,
        borderColor: '#b8cbf5',
        borderRadius: 5,
      },
      dropdown: {
        width: '15%',
        fontFamily: "lato, sans-serif",
        borderColor: '#396DE0',
        backgroundColor: '#F1F1F1',
        borderRadius: 5,
        padding: 10,
      },
      boldText: {
        fontWeight: 700,
        fontFamily: "'lato', sans-serif",
        fontSize: 16,
        marginRight: 5,
      },
      text: {
        fontFamily: "'lato', sans-serif",
        fontSize: 16,
        marginRight: 5,
      },
      creatorStatusSelection: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
      },
      creatorStatusSelectionHighlighted: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#d1e6ff',
        color: 'white',
        padding: 10,
        borderRadius: 5,
      },
      modal: {
        overlay: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: 'rgba(57, 109, 224, .5)'
        },
        content: {
            margin: '20%',
            inset: 0,
            background: 'none',
            flex: 1,
            border: 'none',
            alignSelf: 'center',
            alignContent: 'center',
            outline: 'none',
        }
    },
    sectionText: {
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 30,
        fontWeight: 'bold',
        color: '#396de0',
    },
    greyInput: {
        color: '#737373',
        backgroundColor: '#EFEFEF',
        border: 'none',
        borderRadius: 5,
        width: '100%',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
    },
    creatorsContentGrid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '75%',
        aspectRatio: 1,
        marginTop: 20,
    },
    creatorContentImageView: {
        width: '32%',
        height: '32%',
        marginBottom: '2%',
    },
    creatorContentImage: {
        width: '100%',
        height: '100%',
        aspectRatio: 1,
        paddingTop: 0,
        borderRadius: 5,
    },
    emailIcon: {
        width: 20,
        height: 12,
    }
});

export default Campaign
