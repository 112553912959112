import dupeBusinessClient from "./DupeBusinessClient";

export async function getPopularCreators() {
    return await dupeBusinessClient.get("/api/v1/creators/popular").catch(error => {
        console.log(error);
    });
}

export async function searchCreators(req) {
    return await dupeBusinessClient.post("/api/v1/creators/search", req).catch(error => {
        console.log(error);
    });
}

export async function getUsersPopularPosts(userId) {
    return await dupeBusinessClient.get("/api/v1/creators/" + userId + "/content/popular").catch(error => {
        console.log(error);
    });
}

export async function getCreatorsContent(userId) {
    return await dupeBusinessClient.get("/api/v1/creators/" + userId + "/content").catch(error => {
        console.log(error);
    });
}

export async function getCreatorsContentByAesthetic(userId, aesthetic) {
    return await dupeBusinessClient.get("/api/v1/creators/" + userId + "/content?aesthetic=" + aesthetic).catch(error => {
        console.log(error);
    });
}

export async function getCreatorsAesthetics(userId) {
    return await dupeBusinessClient.get("/api/v1/creators/" + userId + "/aesthetics").catch(error => {
        console.log(error);
    });
}

export async function enableCreatorMessaging(userId) {
    return await dupeBusinessClient.get("/api/v1/creators/" + userId + "/messaging/enabled").catch(error => {
        console.log(error);
    });
}