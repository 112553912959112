import React, { Fragment, useState, useMemo, useEffect } from 'react'
import "../stylesheets/CreateCampaign.css";
import instagramIcon from "../icons/DupeIcons-09-Instagram.png";
import tiktokIcon from "../icons/DupeIcons-10-TikTok.png";
import pencilIcon from "../icons/pencil.png";
import {
    createCampaign,
    createCampaignAttachment,
    createCampaignImage,
    getCampaignByName,
} from "../services/CampaignService";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from './Header';
import Button from './Button';
import CustomCheckbox from './CustomCheckbox';
import { StyleSheet, Text, TextInput, View, TouchableOpacity, Image, Pressable } from 'react-native-web';

const EditCampaign = () => {
    const [campaign, setCampaign] = useState({});
    const navigate = useNavigate();
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [dateSelectionCorrect, setDateSelectionCorrect] = useState(false);
    let { campaignSlug } = useParams();

    useEffect(() => {
        checkIfComplete(campaign);
    }, [campaign]);

    /* Helper function that checks if all required fields are completed for editing or creating a campaign */
    function checkIfComplete(campaign) {
        if (campaign.startDate && campaign.endDate) {
            if (new Date(campaign.startDate) < new Date(campaign.endDate)) {
                setDateSelectionCorrect(true);
            } else {
                setDateSelectionCorrect(false);
            }
        } else {
            setDateSelectionCorrect(false);
        }

        if (campaign.name && campaign.description && (campaign.productCompensation || campaign.paymentCompensation) && dateSelectionCorrect) {
            setEnableSubmit(true);
        } else {
            setEnableSubmit(false);
        }
    }

    function setCoverImage(file) {
        setCampaign((prevState) => { return ({ ...prevState, cover_img: file }) });
    }

    function setAttachment(file) {
        setCampaign((prevState) => { return ({ ...prevState, attachment: file }) });
    }

    function handleProductSelected() {
        setCampaign((prevState) => { return ({ ...prevState, productCompensation: !prevState.productCompensation }) });
    }

    function handlePaymentSelected() {
        setCampaign((prevState) => { return ({ ...prevState, paymentCompensation: !prevState.paymentCompensation }) });
    }

    function setPrivate(value) {
        setCampaign(prevState => ({
            ...prevState,
            private: value === "private"
        }));
    }

    const coverImageUri = useMemo(() => {
        if (campaign.cover_img) {
            return URL.createObjectURL(campaign.cover_img);
        }
        return null;
    }, [campaign.cover_img]);

    function removeBrief() {
        setCampaign((prevState) => { return ({ ...prevState, briefId: '00000000-0000-0000-0000-000000000000' }) });
    }

    function CampaignOverview() {
        return (
            <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>

                <View style={[styles.sectionContainer, { maxWidth: 800, width: '90%' }]}>
                    <Text style={styles.sectionText}>Campaign Overview</Text>
                    <View style={styles.formGroup}>
                        <View>
                            <Text style={styles.boldText}>Visibility</Text>
                            <select
                                style={styles.largeInput}
                                className={"Dropdown-root"}
                                value={campaign.private ? "private" : "public"}
                                onChange={event => setPrivate(event.target.value)}
                            >
                                <option value="private">Private - Content creators can't apply</option>
                                <option value="public">Public - Anyone with a Dupe profile can apply</option>
                            </select>
                            {campaign.private && (
                                <Text style={styles.subtleText}>Your campaign won't appear in campaign searches. Only creators that have already applied or have been added to your campaign can view it.</Text>
                            )}
                            {!campaign.private && (
                                <Text style={styles.subtleText}>Your campaign will appear in campaign searches. All creators can apply to your campaign.</Text>
                            )}
                        </View>
                    </View>
                    <View style={styles.formGroup}>
                        <Text style={styles.boldText}>Campaign Name*</Text>
                        <TextInput
                            value={campaign.name}
                            onChangeText={(text) => setCampaign(prevState => ({ ...prevState, name: text }))}
                            style={{ ...styles.input, ...!campaign.name ? styles.errorBorder : null }}
                        />
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col}>
                            <Text style={styles.boldText}>Campaign Start*</Text>
                            <input style={{ ...styles.smallInput, ...!dateSelectionCorrect ? styles.errorBorder : null }}
                                value={campaign.startDate} onChange={event => setCampaign(prevState => ({ ...prevState, startDate: event.target.value }))} type="date" className="form-control" />
                        </View>
                        <View style={styles.col}>
                            <Text style={styles.boldText}>Campaign End*</Text>
                            <input style={{ ...styles.smallInput, ...!dateSelectionCorrect ? styles.errorBorder : null }}
                                value={campaign.endDate} onChange={event => setCampaign(prevState => ({ ...prevState, endDate: event.target.value }))} type="date" className="form-control" />
                        </View>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text style={styles.boldText}>What type of compensation are you offering?*</Text>
                        <View style={{ ...styles.input, ...{ flexDirection: 'row' }, ...!(campaign.productCompensation || campaign.paymentCompensation) ? styles.errorBorder : null }}>
                            <View style={styles.checkBox}>
                                <CustomCheckbox
                                    value={campaign.productCompensation}
                                    onValueChange={handleProductSelected}
                                />
                                <Text style={styles.text}> Product</Text>
                            </View>
                            <View style={styles.checkBox}>
                                <CustomCheckbox
                                    value={campaign.paymentCompensation}
                                    onValueChange={handlePaymentSelected}
                                />
                                <Text style={styles.text}> Payment</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.formGroup}>
                        <Text style={styles.boldText}>Campaign Description*</Text>
                        <TextInput
                            value={campaign.description}
                            onChangeText={(text) => setCampaign(prevState => ({ ...prevState, description: text }))}
                            style={{ ...styles.input, ...styles.textArea, ...!campaign.description ? styles.errorBorder : null }}
                            multiline
                            numberOfLines={4}
                        />
                        <Text style={styles.subtleText}>Provide a longer description describing this campaign. Creators will see this when they view the campaign.</Text>
                    </View>

                    <View style={styles.formGroup}>
                        <Text style={styles.boldText}>Upload Cover Image</Text>
                        <input style={{ ...styles.largeInput }} type="file" className="form-control" id="exampleInputPassword1"
                            onChange={(event) => {
                                setCoverImage(event.target.files[0]);
                            }} />
                        <Text style={styles.subtleText}>For best results, use .jpg, .jpeg, or .png format and a 1:1 aspect ratio.</Text>
                        {campaign.cover_img && (
                            <Image
                                source={coverImageUri}
                                style={styles.campaignCoverImg}
                                resizeMode="cover"
                            />
                        )}
                        {!campaign.cover_img && campaign.imageExists && (
                            <Image
                                source={'https://d3p3fw3rutb1if.cloudfront.net/business/photos/' + campaign.imageId}
                                style={styles.campaignCoverImg}
                                resizeMode="cover"
                            />
                        )}
                    </View>
                    <View style={styles.formGroup}>
                        {campaign.briefId && campaign.briefId !== '00000000-0000-0000-0000-000000000000' && <View>
                            <Text style={styles.boldText}>Current Campaign Brief</Text>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', }}>
                                <Pressable style={{ minWidth: 125 }}>
                                    <Link
                                        to={"https://d3p3fw3rutb1if.cloudfront.net/business/attachments/" + campaign.briefId}
                                        target="_blank">
                                        <Text style={[styles.previewButtonText, {
                                            textDecorationLine: 'underline',
                                            padding: 0
                                        }]}>View
                                            Campaign Brief
                                        </Text>
                                    </Link>
                                </Pressable>
                                <Pressable onPress={removeBrief}>
                                    <Text style={{ color: 'red' }}>Delete Brief</Text>
                                </Pressable>
                            </View>
                        </View>}
                        {(!campaign.briefId || campaign.briefId === '00000000-0000-0000-0000-000000000000') && <View>
                            <Text style={styles.boldText}>Upload Campaign Brief</Text>
                            <input style={styles.largeInput} type="file" className="form-control" id="exampleInputPassword1"
                                onChange={(event) => {
                                    setAttachment(event.target.files[0]);
                                }} />
                            <Text style={styles.subtleText}>Optional - Upload a document that will be shared with the creator.</Text>
                        </View>}
                    </View>
                    <View style={styles.row}>
                        <Button variant={'secondary'} onPress={() => navigate('/outreach')} text={"Cancel"} style={{ alignSelf: 'flex-start' }} />
                        <Button
                            onPress={enableSubmit ? saveCampaign : null}
                            text="Submit"
                            disabled={!enableSubmit}
                            style={{
                                alignSelf: 'flex-end',
                                backgroundColor: enableSubmit ? '#396DE0' : '#d3d3d3',
                                cursor: enableSubmit ? 'pointer' : 'default',
                            }}
                        />
                    </View>
                </View>
            </View>
        );
    }
    useEffect(()=>{
        if (campaignSlug) {
            getCampaignByName(campaignSlug)
                .then(resp => {
                    if (resp.status === 200 && resp.data) {
                        if (resp.data.startDate) {
                            const startDateTime = resp.data.startDate.split("T");
                            if (startDateTime.length > 0) {
                                resp.data.startDate = startDateTime[0];
                            }
                        }
                        if (resp.data.endDate) {
                            const endDateTime = resp.data.endDate.split("T");
                            if (endDateTime.length > 0) {
                                resp.data.endDate = endDateTime[0];
                            }
                        }
                        setCampaign(resp.data);
                    }
                })
        }
    }, []);





    function saveCampaign() {
        let campaignReq = {};
        if (campaign.startDate) {
            campaignReq.startDate = campaign.startDate + "T00:00:00Z";
        }
        if (campaign.endDate) {
            campaignReq.endDate = campaign.endDate + "T00:00:00Z";
        }
        campaignReq.name = campaign.name;
        campaignReq.description = campaign.description;
        campaignReq.id = campaign.id;
        campaignReq.productCompensation = campaign.productCompensation;
        campaignReq.paymentCompensation = campaign.paymentCompensation;
        campaignReq.private = campaign.private;
        campaignReq.briefId = campaign.briefId;
        campaignReq.imageId = campaign.imageId;
        if (campaign.cover_img) {
            let formData = new FormData();
            formData.append("img", campaign.cover_img);
            createCampaignImage(formData)
                .then(resp => {
                    if (resp.status === 200 && resp.data && resp.data.img_id) {
                        campaignReq.imageId = resp.data.img_id;
                    }
                    if (campaign.attachment) {
                        let fileData = new FormData();
                        fileData.append("file", campaign.attachment);
                        createCampaignAttachment(fileData)
                            .then(attachResp => {
                                if (attachResp.status === 200 && attachResp.data) {
                                    campaignReq.briefId = attachResp.data.id
                                }
                                createCampaign(campaignReq)
                                    .then(resp => {
                                        navigate('/outreach');
                                    })
                            })
                    } else {
                        createCampaign(campaignReq)
                            .then(resp => {
                                navigate('/outreach');
                            })
                    }
                })
        } else {
            if (campaign.attachment) {
                let fileData = new FormData();
                fileData.append("file", campaign.attachment);
                createCampaignAttachment(fileData)
                    .then(attachResp => {
                        if (attachResp.status === 200 && attachResp.data) {
                            campaignReq.briefId = attachResp.data.id
                        }
                        createCampaign(campaignReq)
                            .then(resp => {
                                navigate('/outreach');
                            })
                    })
            } else {
                createCampaign(campaignReq)
                    .then(resp => {
                        navigate('/outreach');
                    })
            }
        }
    }

    return (
        <div>
            <Header title={campaignSlug ? "Edit Campaign" : "Create Campaign"} />
            {CampaignOverview()}
        </div>

    );
}

const styles = StyleSheet.create({
    sectionContainer: {
        backgroundColor: 'white',
        borderRadius: 15,
        border: '1px solid #b8cbf5',
        padding: 20,
    },
    sectionText: {
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 30,
        fontWeight: 'bold',
        color: '#396de0',
    },
    formGroup: {
        marginTop: 20,
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 5,
    },
    boldText: {
        fontFamily: "'lato', sans-serif",
        fontWeight: 'bold',
        marginBottom: 5,
    },
    subtleText: {
        color: '#A6A6A6',
        fontFamily: "'lato', sans-serif",
        marginTop: 5,
    },
    input: {
        border: '1px solid #396DE0',
        borderRadius: 4,
        padding: 10,
        backgroundColor: '#F1F1F1',
    },
    row: {
        flexDirection: 'row',
        marginTop: 20,
        width: '100%',
        justifyContent: 'space-between',
    },
    col: {
        flex: .49,
        width: '45%',
    },
    textArea: {
        height: 100,
    },
    campaignCoverImg: {
        width: 150,
        height: 150,
        borderRadius: 5,
        margin: 10,
        alignSelf: 'center',
    },
    largeInput: {
        width: '97%',
        fontFamily: "lato, sans-serif",
        borderColor: '#396DE0',
        backgroundColor: '#F1F1F1',
        borderRadius: 5,
        padding: 10,
    },
    smallInput: {
        width: '93%',
        fontFamily: "lato, sans-serif",
        borderColor: '#396DE0',
        backgroundColor: '#F1F1F1',
        borderRadius: 5,
        padding: 10,
    },
    errorBorder: {
        borderColor: 'red',
    },
    checkBox: {
        flexDirection: 'row',
        margin: 5,
        marginRight: 25,
    },
    text: {
        fontFamily: "'lato', sans-serif",
    },
    previewButtonText: {
        color: '#396DE0',
        fontWeight: 700,
        fontFamily: "'lato', sans-serif",
        alignSelf: 'center',
        fontSize: 14,
        padding: 10,
    },
});

export default EditCampaign
